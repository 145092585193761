<script setup lang="ts">
import { initialize } from '@bloomreach/spa-sdk'

const { mapping } = useSfBloomreachComponents()
const data = setPageData()
const { buildConfiguration } = useBuildConfiguration()
const configuration = buildConfiguration()
const route = useRoute()
const { trackPageEvent } = useGTMComposable()

if (route.meta?.type === 'ERROR') {
  showError(route.meta?.data)
}

// Hack to reinitialize the page when the website is used via Bloomreach CMS
const visible = ref(!route.query.token)
onMounted(async () => {
  if (route.query.token) {
    visible.value = true
  }
  trackPageEvent()
})

const { data: page } = await useAsyncData(`page_${configuration.path}`, async () => {
  let page
  if (data?.page && !route.query.token) {
    page = initialize(configuration, data.page)
  } else {
    page = await initialize(configuration)
  }
  return page.toJSON()
})
</script>

<template>
  <br-page v-if="visible && page && route.meta?.type !== 'ERROR'" :page :configuration :mapping>
    <slot :data />
  </br-page>
</template>
